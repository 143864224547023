import {Inject, Injectable} from '@angular/core';
import {ENV_CONFIG, EnvironmentConfig} from "../shared/environment-config.interface";

@Injectable()
export class ApiService {

  public apiUrl: string;
  public apiExpress : string;
  public sqlKey: string = "101104-260170";
  public AES128key: string = "ES_ESGESTX2022!";
  public authCondomini: string;

  constructor(@Inject(ENV_CONFIG) private config: EnvironmentConfig) {
    this.apiExpress = `${config.environment.baseUrlExpress}`;
    this.apiUrl = `${config.environment.baseUrl}`;
    this.authCondomini = `${config.environment.urlCheckToken}`
  }
}
