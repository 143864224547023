import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { ApiService } from './api.service';
import { EncodingService } from './encoding.service';

export interface IAbilitazioniResponse {
  Status: {
    errorCode: string,
    errorDescription: string
  }
  Result: IAbilitazioniDataResponse[]
}

export interface IAbilitazioniDataResponse {
  CODGRP: string,
  DESGRP: string,
  NOMECAMPO: string,
  CODMNU : string,
  DESMNU : string,
  ORDINE : string,
  FLGENABLED : string,
}

export interface IAbilitazioniData {
  codGrp: string | undefined,
  desGrp: string | undefined,  
  flgChk: boolean | undefined,
  nomCmp: string | undefined,  
  menu: IAbilitazioniMenuData[]
}

export interface IAbilitazioniMenuResponse {
  Status: {
    errorCode: string,
    errorDescription: string
  }
  Result: IAbilitazioniMenuDataResponse[]
}

export interface IAbilitazioniMenuDataResponse {
  CODMNU: string,
  DESMNU: string,
  ORDINE: string,

}

export interface IAbilitazioniMenuData {
  codMnu: string | undefined,
  desMnu: string | undefined,
  flgChk: boolean | undefined,
  codAbi: number | undefined,
}


@Injectable({
  providedIn: 'root'
})
export class AbilitazioniService {
  public Abilitazioni : IAbilitazioniData[] = [];
  public GrpMenu : IAbilitazioniData[] = [];
  public AbilitazioniMenu : IAbilitazioniMenuData[] = [];
  public GET_ABILITAZIONI_API = "abilitazioni";// "OpenQuery64";
  
  constructor(
    private http:HttpClient,
    private api: ApiService,
    private base64 : EncodingService) { }


    async getAbilitazioni() : Promise<IAbilitazioniData[]>{
      this.Abilitazioni = [];
      return  new Promise((resolve, reject) =>  {
        this.http.post<IAbilitazioniResponse>(this.api.apiExpress + this.GET_ABILITAZIONI_API, '').subscribe((abilitazioni: IAbilitazioniResponse) => {
          let lastCodGrp = '';

          for( let i =0; i < abilitazioni.Result.length; i++) {
            let el = abilitazioni.Result[i];
            let sCodGrp = el.CODGRP;
            let sCodMnu = el.CODMNU;
            if (lastCodGrp != sCodGrp) {
              lastCodGrp = sCodGrp;
              this.Abilitazioni.push({
                codGrp: sCodGrp,
                desGrp: el.DESGRP,
                flgChk: false,
                nomCmp: el.NOMECAMPO,
                menu: []
              });
            }
            let abi = this.Abilitazioni[this.Abilitazioni.length-1];
            if (sCodMnu != '') {
              abi.menu.push({
                codMnu: el.CODMNU,
                desMnu: el.DESMNU,
                flgChk: false,
                codAbi: 10
              });
            }
          }
          resolve(this.Abilitazioni)
        },
        error => {
          console.log(error);
          reject(error)
        });
      });
    }

    /* async getAbilitazioni(sTipiGrp:string) : Promise<IAbilitazioniData[]>{

      this.Abilitazioni = [];
      return  new Promise((resolve, reject) =>  {
        var sSql ="";
        sSql += "SELECT G.CODGRP,G.DESGRP,G.NOMECAMPO ";
        sSql += "FROM MENU_GRP G ";
        sSql += "WHERE G.FLGENABLED = 1 "
        if (sTipiGrp!='') sSql += "AND G.CODGRP IN ("+sTipiGrp+") "
        sSql = sSql + "ORDER BY G.ORDINE,G.DESGRP ";
        this.http.post<IAbilitazioniResponse>(this.api.apiUrl + this.GET_ABILITAZIONI_API, "DB="+btoa('ACCESSI')+"&SQL="+btoa(sSql)).subscribe((abilitazioni: IAbilitazioniResponse) => {
          abilitazioni.Result.forEach(el => { 
            this.Abilitazioni.push({
              codGrp: this.base64.decodeBase64(el.CODGRP),
              desGrp: this.base64.decodeBase64(el.DESGRP),                
              flgChk: false,
              nomCmp: this.base64.decodeBase64(el.NOMECAMPO),
              menu: []
            });
          });
          resolve(this.Abilitazioni)          
        },
        error => {
          console.log(error);
          reject(error)
        });
      });      
    } */

    async getGrpMenu() : Promise<IAbilitazioniData[]>{
      this.GrpMenu = [];
      return  new Promise((resolve, reject) =>  {
        var sSql ="";
        sSql += "SELECT G.CODGRP,G.DESGRP,G.NOMECAMPO ";
        sSql += "FROM MENU_GRP G ";
        sSql += "WHERE G.FLGENABLED = 1 "        
        sSql = sSql + "ORDER BY G.ORDINE,G.DESGRP ";
        this.http.post<IAbilitazioniResponse>(this.api.apiUrl + this.GET_ABILITAZIONI_API, "DB="+btoa('ACCESSI')+"&SQL="+btoa(sSql)).subscribe((abilitazioni: IAbilitazioniResponse) => {
          abilitazioni.Result.forEach(el => { 
            this.GrpMenu.push({
              codGrp: this.base64.decodeBase64(el.CODGRP),
              desGrp: this.base64.decodeBase64(el.DESGRP),                
              flgChk: false,
              nomCmp: this.base64.decodeBase64(el.NOMECAMPO),
              menu: []
            });
          });        
          resolve(this.GrpMenu)          
        },
        error => {
          console.log(error);
          reject(error)
        });
      });      
    }    

    async getAbilitazioniMenu(sCodGrp:string) : Promise<IAbilitazioniMenuData[]>{

      this.AbilitazioniMenu = [];
      return  new Promise((resolve, reject) =>  {
        var sSql = "SELECT CODMNU, DESMNU ";
        sSql = sSql + "FROM MENU ";
        sSql = sSql + "WHERE CODGRP = '"+sCodGrp+"' ";
        sSql = sSql + "AND FLGENABLED = 1 "
        sSql = sSql + "ORDER BY DESMNU ";
        this.http.post<IAbilitazioniMenuResponse>(this.api.apiUrl + this.GET_ABILITAZIONI_API, "DB="+btoa('ACCESSI')+"&SQL="+btoa(sSql)).subscribe((abilitazioniMenu: IAbilitazioniMenuResponse) => {
          abilitazioniMenu.Result.forEach(el => {                    
            this.AbilitazioniMenu.push({
              codMnu: this.base64.decodeBase64(el.CODMNU),
              desMnu: this.base64.decodeBase64(el.DESMNU),              
              flgChk: false,
              codAbi: 10
            });
          });
          console.log('Abilitazioni '+sCodGrp+' caricate')        
          resolve(this.AbilitazioniMenu)
        },
        error => {
          console.log(error);
          reject(error)
        });
      });
    }    

}
