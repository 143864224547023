import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IonAccordion, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { EncodingService } from './encoding.service';
import { AbilitazioniService, IAbilitazioniData} from './abilitazioni.service';
import { MessageService } from './message.service';
import { formatDate, NumberSymbol } from '@angular/common';
import { FuncostantiService } from './funcostanti.service';
import { Observable } from 'rxjs';
import { Parallax } from 'swiper';

//====================================
// P R O F I L O
//====================================
export interface IProfiloResponse {
  Status: {
    errorCode: string,
    errorDescription: string
  }
  Result: IProfiloDataResponse[]
}

export interface IProfiloDataResponse {
  CODUTE: number,
  USRNAME: string,
}

export interface IProfiloData {
  codUte: number | undefined,
  usrName: string | undefined,
}

//====================================
// A C C E S S O - L O G I N
//====================================
export interface IAccessoResponse {
  Status: {
    errorCode: string,
    errorDescription: string
  }
  Result: {
    curUser : IAccessoDataResponse,
    curAbil : IMnuAbilDataResponse[],
  }
}

export interface IAccessoDataResponse {
  CODUTE: number,
  USRNAME: string,
  FLGGDPR: number,
  DATSCAPWD: string,
  STAREG: number,
  KEYREG: string,
  COGNOME : string,
  NOME : string,
  AVATAR : string,
  CODLINGUA : string,
  CELLULARE : string,
  CAUMOV : string,
  FLGSUPER : number,
  FLGMOP : number,
  FLGPIANA : number,
  FLGADDETTI : number,
  FLGOSPITI : number,
  FLGPIANARFID : number,
  FLGCONTA : number,
  FLGTINTEMI : number,
  FLGCUBI : number,
  FLGCICLPASS : number,
  PAGDEF : string,
  PROG : number,
  IDXPERS : number,
  CODCLISUPER : number,
  NUMREP : number,
  CODAGE : number,
  CODCLICOL : number,
  CODCLIENTI : string,
  TIPFIL : number
}

export interface IAccessoData {
  codUte: number | undefined,
  usrName: string | undefined,
  cognome: string | undefined,
  nome: string | undefined,
  avatar: string | undefined,
  cauMov: string | undefined,
  lingua: string | undefined,
  scaPwd: string | undefined,
  flgAdmin: boolean | undefined,
  flgMop: boolean | undefined,
  flgPiana: boolean | undefined,
  flgAddetti: boolean | undefined,
  flgOspiti: boolean | undefined,
  flgGdpr: boolean | undefined,
  tipFiltro: number | undefined,
  idxPers: number | undefined,
  codCliSup: number | undefined,
  codAge: number | undefined,
  codCliCol: number | undefined,
  codClienti:string | undefined,
  numRep: number | undefined,
  desRuo: string | undefined,
  pagDef: string | undefined,
  mnuAbil:IMnuAbilData[];         //i menu abilitati da visualizzare nella barra dei menu
  altreAbil:IAltreAbilData[];     //sono altre abilitazioni di parti nel programma
}

//====================================
// A L T R E  C O S E  A B I L I T A T E
//====================================
export interface IAltreAbilData {
  codGrp: string | undefined,
  codMnu: string | undefined,
  desMnu: string | undefined,
  tipAbi: number | undefined,
  icon: string | undefined,
  tipo: string | undefined
}

//====================================
// M E N U  A B I L I T A T I
//====================================
export interface IMnuAbilResponse {
  Status: {
    errorCode: string,
    errorDescription: string
  }
  Result: IMnuAbilDataResponse[]
}

export interface IMnuAbilDataResponse {
  CODMNU: string,
  TIPABI: number,
  DESMNU: string,
  DESGRP: string,
  CODGRP: string,
  ICON: string,
  TIPO: string,
  PAGINA: string;
}

export interface IAbilitazioniDBResponse {
  Status: {
    errorCode: string,
    errorDescription: string
  },
  Sql : string;
}

export interface IMnuAbilData {
  desGrp: string | undefined,
  codGrp: string | undefined,
  menu: IMnuRigData[]
}

export interface IMnuRigData {
  codMnu: string | undefined,
  desMnu: string | undefined,
  tipAbi: number | undefined,
  icon: string | undefined,
  tipo: string | undefined,
  pagina: string | undefined
}

//====================================
// P R O F I L I
//====================================
export interface IProfiliResponse {
  Status: {
    errorCode: string,
    errorDescription: string
  }
  Result: IProfiliDataResponse[]
}

export interface IProfiliDataResponse {
  CODUTE: number,
  USRNAME: string,
  FLGGDPR: number,
  DATGDPR: string,
  DATINS: string,
  DATSCAPWD: string,
  DATLASTLOGIN: string,
  STAREG: number,
  COGNOME: string,
  NOME: string,
  AVATAR: string,
  FLG2FATT: number,
  CAUMOV: string,
  CODLINGUA: string,
  FLGSUPER: number,
  FLGMOP: number,
  FLGPIANA: number,
  FLGADDETTI: number,
  FLGOSPITI: number,
  FLGPIANARFID: number,
  FLGCONTA: number,
  FLGCUBI: number,
  FLGCICLPASS: number,
  NUMREP: number,
  IDXPERS: number,
  CODCLISUPER: number,
  CODAGE: number,
  CODCLICOL: number,
  CODCLIENTI: string,
  TIPFIL: number,
  PAGDEF: string
}

export interface IProfiliData {
  codUte: number | undefined,
  usrName: string | undefined,
  flgGdpr: number | undefined,
  datGdpr: string | undefined,
  datIns: string | undefined,
  datScapwd: string | undefined,
  datLastlogin: string | undefined,
  staReg: number | undefined,
  cognome: string | undefined,
  nome: string | undefined,
  avatar: string | undefined,
  flg2Fatt: boolean | undefined,
  cauMov: string | undefined,
  codLingua: string | undefined,
  flgSuper: boolean | undefined,
  flgMop: boolean | undefined,
  flgPiana: boolean | undefined,
  flgAddetti: boolean | undefined,
  flgOspiti: boolean | undefined,
  flgPianaRfid: boolean | undefined,
  flgConta: boolean | undefined,
  flgCubi: boolean | undefined,
  flgCiclPass: boolean | undefined,
  numRep: number | undefined,
  idxPers: number | undefined,
  codCliSuper: number | undefined,
  codAge: number | undefined,
  codClicol: number | undefined,
  codClienti: string | undefined,
  tipFil: number | undefined,
  color: string | undefined,
  desRuo: string | undefined,
  pagDef: string | undefined,
  bDettaglio : boolean,
  Abilitazioni : IAbilitazioniData[],
}

@Injectable({
  providedIn: 'root'
})

export class ProfiloService {
  //id selezione filtri per creazione profilo
  ID_TUTTICLIENTI = 10;
  ID_SELCLIENTE = 1;
  ID_SELSUPER   = 2;
  ID_SELCLICOLL = 3;
  ID_SELAGENTE  = 4;
  ID_SELPERSONA = 5;
  ID_SELCAUSALI = 6;
  ID_SELREPARTO = 7;

      //stati di registrazione
  STAREG_NULL  = 0;   //nessun account
  STAREG_INSERT= 5;   //account inserito
  STAREG_INVIO = 10;  //inviata la mail di conferma
  STAREG_CONF  = 20;  //email confermata
  STAREG_DELETE= 50;  //l'utente ha eliminato l'account
  STAREG_BLOCC = 99;  //utente bloccato

  public GET_CODUTE_API = "login/codUte" //"OpenQuery64";
  public GET_LISTA_API = "login/lista" //"OpenQuery64";
  public GET_ABILITAZIONI_UTENTE_API = "login/abilitazioni" //"OpenQuery64";
  public GET_CHECKLOGIN_API = "login/check";
  public ADD_UTENTE = "login/newUtente";
  public DEL_ABILITAZIONI_UTENTE_API = "login/delAbilitazioni";
  public SET_FILTRI_UTENTE_API = "login/addFiltri";
  public SET_STAREG_UTENTE_API = "login/setStaReg";
  public SET_GDPR_UTENTE_API = "login/setGDPR";

  public SET_ABILITAZIONI_API = "ExecMultiQuery64";
  public SET_PASSWORD_API = "login/setPassword";

  public Accesso:IAccessoData[]=[];
  public MnuAbil:IMnuAbilData[]=[];
  public MnuRig:IMnuRigData[]=[];
  public Altre:IAltreAbilData[]=[];
  public Profili:IProfiliData[]=[];

  public tipoAbilitazione:any=[
    {id:0,desc:'Nessuna'},
    {id:10,desc:'Lettura'},
    {id:20,desc:'Scrittura'}
  ];

  public ABIL_NONE = 0
  public ABIL_READ = 10
  public ABIL_WRITE = 20

  constructor(
    private http:HttpClient,
    private api: ApiService,
    public AbilitazioniService:AbilitazioniService,
    public MessageService:MessageService,
    public router: Router,
    public navCtrl : NavController,
    public funConst:FuncostantiService,
    private base64 : EncodingService) { }

  //** restituisce il codice utente passandogli lo username */
  async getCodUtente(sUsrName:string) : Promise<number>{
    return  new Promise((resolve, reject) =>  {
      let params = {
        userName : sUsrName
      }
      this.http.post(this.api.apiExpress + this.GET_CODUTE_API, params).subscribe(res => {
        var object = JSON.stringify(res);
        var json = JSON.parse(object);
        if(json.Result[0])
          resolve(json.Result[0].CODUTE);
        else
          resolve(null)
      },
      error => {
        console.log(error);
        reject("")
      });
    });
  }



  //** inserisce il nuovo profilo */
/*
  async newUtente(iCodUte:number,sUsrName:string,sCognome:string,sNome:string,sLingua:string,iStaReg:number,sKeyReg:string,sCodCau:string,iAdmin:number,sCampi:string,sValori:string) : Promise<boolean>{
    return  new Promise((resolve, reject) =>  {
      var sSql = "INSERT INTO UTENTI (CODUTE,USRNAME,STAREG,KEYREG,FLGGDPR) VALUES (";
      sSql += iCodUte+",'"+sUsrName.toLowerCase().trim()+"',"+iStaReg+",'"+sKeyReg.trim()+"',0)";

      this.http.post(this.api + this.GET_PROFILO_EXEC_API, "DB="+btoa('ACCESSI')+"&SQL="+btoa(sSql)+"&KEY="+btoa(this.api.sqlKey)).subscribe(res => {
        var object = JSON.stringify(res);
        var json = JSON.parse(object);
        if (json.Status.errorCode==0) {  //inserisco i dati anagrafici e di configurazione

          sSql = "INSERT INTO UTENTI_CONFIG (CODUTE,COGNOME,NOME,CAUMOV,CODLINGUA,FLGSUPER"+sCampi;
          sSql += ") VALUES ( ";
          sSql += iCodUte+",'"+sCognome.replace("'","''").trim()+"',"
          sSql += "'"+sNome.replace("'","''").trim()+"',"
          sSql += "'"+sCodCau+"','"+sLingua.toUpperCase().trim()+"',"+iAdmin+sValori+")";
          this.http.post(this.api. + this.GET_PROFILO_EXEC_API, "DB="+btoa('ACCESSI')+"&SQL="+btoa(sSql)+"&KEY="+btoa(this.api.sqlKey)).subscribe(res => {
            var object = JSON.stringify(res);
            var json = JSON.parse(object);
            resolve(json.Status.errorCode==0);
          },
          error => {
            console.log(error);
            reject("")
          });
        }
      },
      error => {
        console.log(error);
        reject("")
      });
    });
  }
*/
async newUtente(iCodUte:number,sUsrName:string,sCognome:string,sNome:string,sLingua:string,iStaReg:number,sKeyReg:string,sCodCau:string,iAdmin:number,sCampi:string,sValori:string) : Promise<boolean>{
  return  new Promise((resolve, reject) =>  {
    let params = {
      iCodUte : iCodUte,
      sUsrName :sUsrName,
      sCognome :sCognome,
      sNome : sNome,
      iStaReg : iStaReg,
      sLingua : sLingua,
      sKeyReg : sKeyReg,
      sCodCau : sCodCau,
      iAdmin : iAdmin,
      sCampi : sCampi,
      sValori : sValori

    }
    this.http.post(this.api.apiExpress + this.ADD_UTENTE, params).subscribe(res => {
      var object = JSON.stringify(res);
      var json = JSON.parse(object);
      resolve(json.Status.errorCode==0);
    },
    error => {
      console.log(error);
      reject("")
    });
  });
}


  //** inserisce i filtri scelti */
  async addFiltri(iCodUte:number,iFiltro:number,sCodCli:string,sCodInt:string,iNumRep:number,iIdxPers:number,iSuper:number,iAgente:number,iCliCol:number) : Promise<boolean>{
    return  new Promise((resolve, reject) =>  {
      let params = {
        codUte : iCodUte,
        filtro : iFiltro,
        codCli : sCodCli,
        numRep : iNumRep,
        codSuper : iSuper,
        agente : iAgente
      }
      this.http.post(this.api.apiExpress + this.SET_FILTRI_UTENTE_API, params).subscribe(res => {
        var object = JSON.stringify(res);
        var json = JSON.parse(object);
        resolve(json.Status.errorCode==0)
      },
      error => {
        console.log(error);
        reject("")
      });
    });
  }

  wsDeleteDBAbilitazioni(iCodUte : number) : Observable<IAbilitazioniDBResponse> {
    let params = {
      codUte : iCodUte
    }
    return this.http.post<IAbilitazioniDBResponse>(this.api.apiExpress + this.DEL_ABILITAZIONI_UTENTE_API, params);

  }

/*
  async addAbilitazioni(iCodUte:number,mnuAbil:IAbilitazioniData[]) : Promise<boolean>{
    return  new Promise((resolve, reject) =>  {
      let SqlScript = [];
      let i = 0;

      var sSql = "delete from ABILITAZIONI WHERE CODUTE=" + iCodUte.toString();
        SqlScript.push({
        idx : 0,
        sql : sSql
      });
      let n = 0;
      for( i=0;i<mnuAbil.length;i++){
        for(var j=0;j<mnuAbil[i].menu.length;j++){
          if (mnuAbil[i].menu[j].flgChk){
            let sSql:string='UPDATE OR INSERT INTO ABILITAZIONI (CODUTE,CODMNU,TIPABI) VALUES (';
            sSql += iCodUte+",'"+mnuAbil[i].menu[j].codMnu+"',"+mnuAbil[i].menu[j].codAbi+")"
            SqlScript.push({
                idx : ++n,
                sql : sSql
            });
          }
        }
      }

      let sql = '{"sql": '+ JSON.stringify(SqlScript) + '}';
      //console.log(sql);
      let body = 'SQL='+ btoa(sql);
      body += "&KEY=" + btoa(this.api.sqlKey);

      this.http.post(this.api + this.SET_ABILITAZIONI_API, "DB="+btoa('ACCESSI') + "&" + body).subscribe(res => {
        var object = JSON.stringify(res);
        var json = JSON.parse(object);
        resolve(json.Status.errorCode==0)
      },
      error => {
        console.log(error);
        reject(false)
      });
    });
  }
*/

async addAbilitazioni(iCodUte:number,mnuAbil:IAbilitazioniData[]) : Promise<boolean>{
  return  new Promise((resolve, reject) =>  {
    let params = {
      iCodUte : iCodUte,
      mnuAbil : mnuAbil
    }

    console.log("Abilitazioni: ");
    console.log(params);
    this.http.post(this.api.apiExpress + this.SET_ABILITAZIONI_API, params).subscribe(res => {
      var object = JSON.stringify(res);
      var json = JSON.parse(object);
      resolve(json.Status.errorCode==0)
    },
    error => {
      console.log(error);
      reject(false)
    });
  });
}


  //** cambia il valore dello stato di registrazione */
  async changeSTAREG(iCodUte:number,iStaReg:number) : Promise<boolean>{
    return  new Promise((resolve, reject) =>  {
      let params = {
        codUte : iCodUte,
        staReg : iStaReg
      }
      this.http.post(this.api.apiExpress + this.SET_STAREG_UTENTE_API, params).subscribe(res => {
        var object = JSON.stringify(res);
        var json = JSON.parse(object);
        resolve(json.Status.errorCode==0)
      },
      error => {
        console.log(error);
        reject("")
      });
    });
  }

  //** setta la nuova password */
  async setPassword(newPwd:string,sCodUte:string):Promise<boolean>{
    return  new Promise((resolve, reject) =>  {
      let params = {
        sCodUte : sCodUte,
        newPwd : newPwd
      }
      this.http.post(this.api.apiExpress + this.SET_PASSWORD_API, params).subscribe(res => {
        var object = JSON.stringify(res);
        var json = JSON.parse(object);
        if (json.Status.errorCode==0) {
          this.changeSTAREG(+sCodUte,this.STAREG_CONF).then(async res => {
            resolve(res);
          });
        }
        else {
          resolve(false)
        }
      },
      error => {
        console.log(error);
        reject("")
      });
    });
  }

  //** setta l'accettazione del nuovo GDPR */
  async setGDPR(iCodUte:number):Promise<boolean>{
    return  new Promise((resolve, reject) =>  {
      let params = {
        codUte : iCodUte
      }
      this.http.post(this.api.apiExpress + this.SET_GDPR_UTENTE_API, params).subscribe(res => {
        var object = JSON.stringify(res);
        var json = JSON.parse(object);
        resolve(json.Status.errorCode==0);
      },
      error => {
        console.log(error);
        reject("")
      });
    });
  }

  GetDesFiltro(iFiltro:number):string{
    let sRuo:string="";
    switch (iFiltro){
      case this.ID_SELCLIENTE: {   //uno o più clienti
        sRuo = "uno o più clienti";
        break;
      }
      case this.ID_SELSUPER: {     //super cliente
        sRuo = "cliente padre";
        break;
      }
      case this.ID_SELCLICOLL: {   //clienti collegati commercialmente
        sRuo = "clienti collegati";
        break;
      }
      case this.ID_SELAGENTE: {    //commerciale
        sRuo = "commerciale";
        break;
      }
      case this.ID_SELPERSONA: {   //singola persona/ospite
        sRuo = "privato";
        break;
      }
    }
    return sRuo;
  }


  async getProfili(abilitazioni : IAbilitazioniData[]):Promise<IProfiliData[]>{
    this.Profili=[];
    return  new Promise((resolve, reject) =>  {
      this.http.post<IProfiliResponse>(this.api.apiExpress+ this.GET_LISTA_API, '').subscribe((prof: IProfiliResponse) => {
        prof.Result.forEach(el => {
          let sColore:string='primary'
          switch (+el.STAREG) {
            case this.STAREG_NULL:{
              sColore = "medium";
              break;
            }
            case this.STAREG_INSERT:{
              sColore = "light";
              break;
            }
            case this.STAREG_INVIO:{
              sColore = "warning";
              break;
            }
            case this.STAREG_CONF:{
              sColore = "success";
              break;
            }
            case this.STAREG_DELETE:{
              sColore = "danger";
              break;
            }
            case this.STAREG_BLOCC:{
              sColore = "dark";
              break;
            }
          }
          let sRuo:string=this.GetDesFiltro(+el.TIPFIL);
          if (+el.FLGSUPER==1) sRuo = "ADMIN";
          this.Profili.push({
            codUte: +el.CODUTE,
            usrName: el.USRNAME,
            flgGdpr: +el.FLGGDPR,
            datGdpr: el.DATGDPR,
            datIns: el.DATINS,
            datScapwd: el.DATSCAPWD,
            datLastlogin: el.DATLASTLOGIN,
            staReg: +el.STAREG,
            cognome:el.COGNOME,
            nome:el.NOME,
            avatar:el.AVATAR,
            flg2Fatt: +el.FLG2FATT==1,
            cauMov:el.CAUMOV,
            codLingua:el.CODLINGUA,
            flgSuper: +el.FLGSUPER==1,
            flgMop: +el.FLGMOP==1,
            flgPiana: +el.FLGPIANA==1,
            flgAddetti: +el.FLGADDETTI==1,
            flgOspiti: +el.FLGOSPITI==1,
            flgPianaRfid: +el.FLGPIANARFID==1,
            flgConta: +el.FLGCONTA==1,
            flgCubi: +el.FLGCUBI==1,
            flgCiclPass: +el.FLGCICLPASS==1,
            numRep: +el.NUMREP,
            idxPers: +el.IDXPERS,
            codCliSuper: +el.CODCLISUPER,
            codAge: +el.CODAGE,
            codClicol: +el.CODCLICOL,
            codClienti: el.CODCLIENTI,
            tipFil: +el.TIPFIL,
            color: sColore,
            desRuo: sRuo,
            pagDef: el.PAGDEF,
            bDettaglio : false,
            Abilitazioni : [],
          });

          // Aggiungere abilitazioni e menu
          let p = this.Profili[this.Profili.length-1];
          for ( let a = 0; a < abilitazioni.length; a++) {
            let ab = abilitazioni[a];
              p.Abilitazioni.push({
              codGrp: ab.codGrp,
              desGrp: ab.desGrp,
              flgChk: ab.flgChk,
              menu : [],
              nomCmp : ab.nomCmp
            });
            for( let m= 0; m< ab.menu.length; m++) {
              let men = ab.menu[m];

              p.Abilitazioni[p.Abilitazioni.length-1].menu.push({
                codAbi : men.codAbi,
                codMnu : men.codMnu,
                desMnu : men.desMnu,
                flgChk : men.flgChk,
              });
            }
          }



        });
        resolve(this.Profili)
      },
      error => {
        console.log(error);
        reject(error)
      });
    });
  }


  //====================================
  // A C C E S S O - L O G I N
  //====================================
  async getCheckLogin( sUser : string, sPass : string) : Promise<boolean>{
    this.Accesso=[];
    this.MnuAbil=[];
    this.MnuRig=[];
    this.Altre=[];
    return  new Promise((resolve, reject) =>  {
      let params = {
        sUser : sUser,
        sPass : sPass
      }
      this.http.post<IAccessoResponse>(this.api.apiExpress + this.GET_CHECKLOGIN_API, params).subscribe((check : IAccessoResponse) => {
        if(check.Status.errorCode != '0') {
          this.MessageService.MsgErr('Login', check.Status.errorDescription);
          resolve(false);
        }
        let curUser = check.Result.curUser;
        let curAbil = check.Result.curAbil;

        // console.log(check.Result);
        console.log(curAbil);
        let sGrp:string=curAbil[0].CODGRP;
        let sDes:string=curAbil[0].DESGRP;
        for (let i=0; i < curAbil.length; i++){
          if (curAbil[i].TIPO=='M') {      //menù da visualizzare nella barra dei menu
            if ( sGrp!=curAbil[i].CODGRP) {
              this.MnuAbil.push({
                codGrp: sGrp,
                desGrp: sDes,
                menu: this.MnuRig
              })
              this.MnuRig=[];
            }
            this.MnuRig.push({
              codMnu: curAbil[i].CODMNU,
              desMnu: curAbil[i].DESMNU,
              tipAbi: curAbil[i].TIPABI,
              icon: curAbil[i].ICON,
              tipo: curAbil[i].TIPO,
              pagina: curAbil[i].PAGINA,
            })
            sGrp=curAbil[i].CODGRP;
            sDes=curAbil[i].DESGRP;
          }
          else {    //altre abilitazioni di parti del programma
            this.Altre.push({
              codGrp: curAbil[i].CODGRP,
              codMnu: curAbil[i].CODMNU,
              desMnu: curAbil[i].DESMNU,
              tipAbi: curAbil[i].TIPABI,
              icon: curAbil[i].ICON,
              tipo: curAbil[i].TIPO,
            })
          }
        }
        this.MnuAbil.push({
          codGrp: sGrp,
          desGrp: sDes,
          menu: this.MnuRig
        })
        this.Accesso.push({
          codUte:curUser.CODUTE,
          usrName:sUser,
          cognome: curUser.COGNOME,
          nome: curUser.NOME,
          avatar: curUser.AVATAR,
          cauMov: curUser.CAUMOV,
          lingua: curUser.CODLINGUA,
          scaPwd: curUser.DATSCAPWD,
          flgAdmin: curUser.FLGSUPER==1,
          flgMop: curUser.FLGMOP==1,
          flgPiana: curUser.FLGPIANA==1,
          flgAddetti: curUser.FLGADDETTI==1,
          flgOspiti: curUser.FLGOSPITI==1,
          flgGdpr: curUser.FLGGDPR==1,
          tipFiltro: curUser.TIPFIL,
          idxPers: curUser.IDXPERS,
          codCliSup: curUser.CODCLISUPER,
          codAge: curUser.CODAGE,
          codCliCol: curUser.CODCLICOL,
          codClienti: curUser.CODCLIENTI,  //lista dei clienti o del cliente che può visualizzare
          numRep: curUser.NUMREP,
          desRuo: "",
          pagDef: curUser.PAGDEF,
          mnuAbil: this.MnuAbil,
          altreAbil: this.Altre,
        })
        this.Accesso[0].desRuo = this.GetDesFiltro(+this.Accesso[0].tipFiltro);
        console.log( ' ************* LOGIN *******************');
        console.log(this.Accesso[0]);
        if (this.Accesso[0].flgAdmin) this.Accesso[0].desRuo = "ADMIN";
        this.setLoginData(this.Accesso);

        resolve(true)


      },
      error => {
        this.MessageService.MsgErr('Login', error.error.Status.errorDescription);
        console.log(error);
        this.setLoginData(undefined);
        reject(error)
      });
    });
  }

  getLoginData() : IAccessoData[] {
    let sTmp = localStorage.getItem('UtenteLogin');
    if (sTmp != '') {
      return JSON.parse(sTmp);
    }
    return undefined;
  }

  setLoginData( aData : IAccessoData[]) {
    if (aData) {
      localStorage.setItem('UtenteLogin', JSON.stringify(aData));
    }
    else {
      localStorage.setItem('UtentiLogin', '');
    }
  }

  getAccessoValido() : IAccessoData[] {
    return this.getLoginData();
  }

  MakeRandom(lengthOfCode: number) {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  //*************************************************************************/
  //** verifica l'abilitazione ad una parte di programma                    */
  //** sCodMenu: codice del menu da verificare                              */
  //** arMnu: è l'array AltreAbil dell'utente                               */
  //** bAdmin: indica se utente amministratore, quindi ha tutto abilitato   */
  //** return 0 se il codice non esiste, altrimenti il tipo di abilitazione */
  //*************************************************************************/
  getAltreAbil_new(sCodMnu:string,arMnu:IAltreAbilData[],bAdmin:boolean):number{
    if (!bAdmin) {
      const idx = arMnu.findIndex((i: IAltreAbilData) => {
        return (i.codMnu === sCodMnu);
      })
      if (idx>=0)
        return arMnu[idx].tipAbi
      else
        return 0
    }
    else
      return 10
  }

  getAltreAbil(sCodMnu:string):number{
    this.Accesso = this.getAccessoValido();
    if (this.Accesso.length>0) {
      const idx = this.Accesso[0].altreAbil.findIndex((i: IAltreAbilData) => {
        return (i.codMnu === sCodMnu);
      })
      if (idx>=0) {
        if (!this.Accesso[0].flgAdmin) {
          return this.Accesso[0].altreAbil[idx].tipAbi
        }
        else
          return 10
      }
      else
        return 0
    }
    else
      return 0
  }

  getCurAbil(sCodMnu : string) {
    let accesso : IAccessoData[] = this.getAccessoValido();
    if (accesso.length == 0) {
      return this.ABIL_NONE;
    }
    const idx = accesso[0].altreAbil.findIndex((i: IAltreAbilData) => {
      return (i.codMnu === sCodMnu);
    })
    if (idx>=0)
      return accesso[0].altreAbil[idx].tipAbi
    else
      return this.ABIL_NONE;

  }

  getNomeCognome() : string {
    let sRet = '';
    let accesso : IAccessoData[] = this.getAccessoValido();
    if (accesso != undefined) {
      if (accesso.length != 0 )
        sRet =  accesso[0].nome + ' ' + accesso[0].cognome;
    }
    return sRet;

  }

  getTipoAbilitazione(iCodAbi:number){
    let i=this.tipoAbilitazione.findIndex(x=> x.id ==iCodAbi)
    return this.tipoAbilitazione[i].desc;
  }
    getAbilitazioniByUtente( aAbil : IAbilitazioniData[], aCodUte : string) {
    let params = {
      codUte : aCodUte
    }

    this.http.post<IMnuAbilResponse>(this.api.apiExpress + this.GET_ABILITAZIONI_UTENTE_API, params).subscribe((mnu: IMnuAbilResponse) => {
      var i:number;
      for (i=0;i<mnu.Result.length;i++) {
        let codGrp = mnu.Result[i].CODGRP;
        let codMnu = mnu.Result[i].CODMNU;
        let valAbi = +mnu.Result[i].TIPABI;
        let tipo = mnu.Result[i].TIPO;

        if (tipo=='M') {      //primo livello gruppi menu
          for (let m= 0; m < aAbil.length; m ++) {
            if (aAbil[m].codGrp == codGrp) {
              aAbil[m].flgChk = true;
              break;
            }
          }
        }
        else {    //altre abilitazioni di parti del programma
          for (let m= 0; m < aAbil.length; m ++) {
            if (aAbil[m].codGrp == codGrp) {
              for( let a=0; a < aAbil[m].menu.length; a++) {
                let men = aAbil[m].menu[a];
                if (men.codMnu == codMnu) {
                  men.codAbi = valAbi;
                  men.flgChk = true;
                }
              }
            }
          }
        }
      }
    });

  }

  getFiltroClienti():string{
    let strFiltro:string='';
    this.Accesso = this.getAccessoValido();
    if (this.Accesso.length>0) {
      console.log('*** FILTRO CLIENTE', this.Accesso[0])
      switch (this.Accesso[0].tipFiltro){
        case this.funConst.ID_SELAGENTE: {
          if (this.Accesso[0].codAge) {
            strFiltro = "AND C.CODAGE = "+this.Accesso[0].codAge;
          }
          else {
            console.log('*** ERROR FILTRO AGENTE tipo selezione agente ma nessun codAge selezionato')
          }

          break;
        }
        case this.funConst.ID_SELCLICOLL: {
          strFiltro = "AND C.CODCLICOL = "+this.Accesso[0].codCliCol;
          break;
        }
        case this.funConst.ID_SELCLIENTE: {
          if (this.Accesso[0].codClienti) {
            strFiltro = "AND C.CODCLI IN ("+this.Accesso[0].codClienti+")";
          }
          else {
            console.log('*** ERROR FILTRO CLIENTI tipo selezione cliente ma nessun codClienti selezionato')
          }
          break;
        }
        case this.funConst.ID_SELSUPER: {
          if (this.Accesso[0].codClienti) {
            strFiltro = "AND C.CODCLISUPER = '"+this.Accesso[0].codCliSup+"'";
          }
          else {
            console.log('*** ERROR FILTRO SUPERCLIENTE tipo selezione supercliente ma nessun codCliSup selezionato')
          }
          break;
        }
        default:{
          strFiltro = "";
          break;
        }
      }
    }
    return strFiltro;
  }



}
