import { Component } from '@angular/core';
import { IAccessoData,ProfiloService } from './Service/profilo.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  public accesso : IAccessoData[]=[]; 
  
  constructor(public profilo : ProfiloService, private translate: TranslateService) {
    this.accesso = this.profilo.getAccessoValido();
    translate.setDefaultLang('it');
    translate.use('it');
  }
  
}
