import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo:'login',
    pathMatch: 'full'
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'versioni',
    loadChildren: () => import('./versioni/versioni.module').then( m => m.VersioniPageModule)
  },
  {
    path: 'profilo',
    loadChildren: () => import('./profilo/profilo.module').then( m => m.ProfiloPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'lista-clienti',
    loadChildren: () => import('./lista-clienti/lista-clienti.module').then( m => m.ListaClientiPageModule)
  },
  {
    path: 'statistiche',
    loadChildren: () => import('./statistiche/statistiche.module').then( m => m.StatistichePageModule)
  },
  {
    path: 'cliente',
    loadChildren: () => import('./cliente/cliente.module').then( m => m.ClientePageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'profilo-new',
    loadChildren: () => import('./profilo-new/profilo-new.module').then( m => m.ProfiloNewPageModule)
  },
  {
    path: 'registra-articolo',
    loadChildren: () => import('./registra-articolo/registra-articolo.module').then( m => m.RegistraArticoloPageModule)
  },
  {
    path: 'mop-stat',
    loadChildren: () => import('./mop-stat/mop-stat.module').then( m => m.MopStatPageModule)
  },
  {
    path: 'dashboard-rfid',
    loadChildren: () => import('./dashboard-rfid/dashboard-rfid.module').then( m => m.DashboardRfidPageModule)
  },
  {
    path: 'renewpwd',
    loadChildren: () => import('./renewpwd/renewpwd.module').then( m => m.RenewpwdPageModule)
  },
  {
    path: 'gdpr',
    loadChildren: () => import('./gdpr/gdpr.module').then( m => m.GdprPageModule)
  },
  {
    path: 'lista-profili',
    loadChildren: () => import('./lista-profili/lista-profili.module').then( m => m.ListaProfiliPageModule)
  },
  {
    path: 'lista-personale',
    loadChildren: () => import('./lista-personale/lista-personale.module').then( m => m.ListaPersonalePageModule)
  },
  {
    path: 'select-cliente',
    loadChildren: () => import('./select-cliente/select-cliente.module').then( m => m.SelectClientePageModule)
  },
  {
    path: 'select-reparto',
    loadChildren: () => import('./select-reparto/select-reparto.module').then( m => m.SelectRepartoPageModule)
  },
  {
    path: 'select-mansione',
    loadChildren: () => import('./select-mansione/select-mansione.module').then( m => m.SelectMansionePageModule)
  },
  {
    path: 'lista-profili',
    loadChildren: () => import('./lista-profili/lista-profili.module').then( m => m.ListaProfiliPageModule)
  },
  {
    path: 'prebolle',
    loadChildren: () => import('./prebolle/prebolle.module').then( m => m.PrebollePageModule)
  },
  {
    path: 'scheda-addetto',
    loadChildren: () => import('./scheda-addetto/scheda-addetto.module').then( m => m.SchedaAddettoPageModule)
  },
  {
    path: 'mappa',
    loadChildren: () => import('./mappa/mappa.module').then( m => m.MappaPageModule)
  },
  {
    path: 'supervisor',
    loadChildren: () => import('./supervisor/supervisor.module').then( m => m.SupervisorPageModule)
  },
  {
    path: 'prospetto-mop',
    loadChildren: () => import('./prospetto-mop/prospetto-mop.module').then( m => m.ProspettoMopPageModule)
  },
  {
    path: 'planning',
    loadChildren: () => import('./Planning/planning.module').then( m => m.PlanningPageModule)
  },

  {
    path: 'lista-stiro',
    loadChildren: () => import('./lista-stiro/lista-stiro.module').then( m => m.ListaStiroPageModule)
  },
  {
    path: 'lista-cpz',
    loadChildren: () => import('./lista-cpz/lista-cpz.module').then( m => m.ListaCpzPageModule)
  },
  {
    path: 'riepilog-azienda',
    loadChildren: () => import('./riepilog-azienda/riepilog-azienda.module').then( m => m.RiepilogAziendaPageModule)
  },
  {
    path: 'cliente-landing',
    loadChildren: () => import('./cliente-landing/cliente-landing.module').then( m => m.ClienteLandingPageModule)
  },
  {
    path: 'contabilita/:pagina',
    loadChildren: () => import('./contabilita/contabilita.module').then( m => m.ContabilitaPageModule)
  },
  {
    path: 'contabilita',
    loadChildren: () => import('./contabilita/contabilita.module').then( m => m.ContabilitaPageModule)
  },
  {
    path: 'gp-maschere',
    loadChildren: () => import('./gp-maschere/gp-maschere.module').then( m => m.GpMascherePageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
