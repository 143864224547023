import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FuncostantiService {

  APP_MAIL = 'segnalazioni@virdis.si';

  //di selezione filtri per creazione profilo
  ID_SELCLIENTE = 1;
  ID_SELSUPER   = 2;
  ID_SELCLICOLL = 3;
  ID_SELAGENTE  = 4;
  ID_SELPERSONA = 5;
  ID_SELCAUSALI = 6;
  ID_SELREPARTO = 7;
  ID_ADMIN      = 99;

  //stati di registrazione
  STAREG_NULL  = 0;   //nessun account
  STAREG_INSERT= 5;   //account inserito
  STAREG_INVIO = 10;  //inviata la mail di conferma
  STAREG_CONF  = 20;  //email confermata
  STAREG_DELETE= 50;  //l'utente ha eliminato l'account
  STAREG_BLOCC = 99;  //utente bloccato  

  //stati personale
  STATO_ATTIVO  = 1;
  STATO_DIMESSO = 3;

  //mesi
  mese_esteso = ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];
  mese_breve  = ['Gen.','Feb.','Mar.','Apr.','Mag.','Giu.','Lug.','Ago.','Set.','Ott.','Nov.','Dic.'];

  //giorni
  giorno_esteso = ['Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato','Domenica'];
  giorno_breve  = ['Lun','Mar','Mer','Gio','Ven','Sab','Dom'];

  constructor() { }
}
