import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    public alertControl : AlertController,
    public Translate: TranslateService
  ) { }


  async  Sicuro(aSub, aMsg : string): Promise<boolean> {
    var attenzione = this.Translate.instant('attenzione');
    const alert = await this.alertControl.create({
      header : attenzione,
      subHeader : aSub,
      message : aMsg,
      buttons : [{ text: 'Si', role : 'S'}, { text: 'No', role : 'N'}]
    });

    await alert.present();

    var ret = await alert.onDidDismiss();
    console.log(ret);

    return new Promise((resolve, reject) => {
      resolve(ret.role == 'S');
    });
    // return ret.role == 'S';

  }

  async  MsgInfo(aSub, aMsg : string) {
    var info = this.Translate.instant('informazione');
    const alert = await this.alertControl.create({
      header : info,
      subHeader : aSub,
      message : aMsg,
      buttons : ['OK']
    });

    await alert.present();


  }
  async  MsgErr(aSub, aMsg : string) {
    var errore = this.Translate.instant('errore');
    const alert = await this.alertControl.create({
      header : errore,
      subHeader : aSub,
      message : aMsg,
      cssClass: 'msgError',
      buttons : ['OK']
    });

    await alert.present();
  }


}
