import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NavController, ViewWillEnter } from '@ionic/angular';
import { IAccessoData,ProfiloService } from '../Service/profilo.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, ViewWillEnter{
  public accesso : IAccessoData[]=[];
  public areAccessiAvailable: boolean = false;

  constructor(
    public profilo : ProfiloService,
    public navCtrl : NavController,
    ){
    this.getAccessi();

  }
  ionViewWillEnter(): void {
    this.getAccessi();
  }

  ngOnInit() {
      this.getAccessi();
  }

  getAccessi(){
    this.accesso = this.profilo.getAccessoValido();
    // console.log(this.accesso)
    console.log('acessiav', this.areAccessiAvailable)
    if (this.accesso != null) this.areAccessiAvailable = true;
  }

  doLogOut() {
    var lingua = localStorage.getItem("SelLingua");
    localStorage.clear();
    localStorage.setItem("SelLingua", lingua);
    this.navCtrl.navigateBack('/login');
  }
  
  doProfilo() {
    this.navCtrl.navigateForward('/profilo');
  }
}
