import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { ENV_CONFIG } from '../shared/environment-config.interface';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

export interface IAuthResponse {
  Result: boolean,
  Error: any,
  Other: any
}

export interface IAuthParams {
  token: string;
  codicefiscale: string;
  iframe?: number;
  iframenewtab?: number;
  ruolo?: string;
  pagina?: number;
}

@Injectable({
  providedIn: 'root'
})
export class LoginCondominiService {

  private AUTH_CONDOMINI = "login/authCondomini";
  private GET_TOKEN = "login/getCondominioToken";
  public token = "";

  constructor(
    private http: HttpClient,
    private api: ApiService,

  ) {
    console.log("########### e n v i r o n m e n t  ", environment.production);

    if (!environment.production) {
      this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiQ09ORDIwMjQwMDAwMDI1IiwiaWF0IjoxNzIzMTA2NTMyLCJleHAiOjE3NTAxOTA2NTMyfQ.Lz28wOMlHfpUaHYgxgCfvFT4Y71_VUiDbhZKsuA2ST8";
      console.log("Selezionato Condominio San Rocco 3 ", this.token);
      console.log("Altri per test: 0123456789123456 => ", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiMDEyMzQ1Njc4OTEyMzQ1NiIsImlhdCI6MTcyMzEwNjY3NSwiZXhwIjoxNzUwMTkwNjY3NX0.PJO8JB2hseDYtLdWFJi1yP399_vK1eX3lROrcYsOmCY");
    }

    /** condominio Larici */
    // if (!environment.production) {
    //   this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiMDAwMDAwMDAwMDAwMDAwMSIsImlhdCI6MTcyNTUyNzY4MywiZXhwIjoxNzUwNDMyNzY4M30.jYW-agaEbQ9Ysfs00vnKPkRpeO5zC8C8CICmrrkBkZk";
    //   console.log("Selezionato Condominio Larici ", this.token);
    //   console.log("Altri per test: 0123456789123456 => ", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiMDEyMzQ1Njc4OTEyMzQ1NiIsImlhdCI6MTcyMzEwNjY3NSwiZXhwIjoxNzUwMTkwNjY3NX0.PJO8JB2hseDYtLdWFJi1yP399_vK1eX3lROrcYsOmCY");
    // }
    
    /** condominio Salici */
    // if (!environment.production) {
      //   this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiQ09ORDIwMjQwMDAwMDEzIiwiaWF0IjoxNzI1OTU1NzQwLCJleHAiOjE3NTA0NzU1NzQwfQ.8rksjxkuiQqoaLVo8moo_whFAbYnn86IzWMUU9yLzG0";
      //   console.log("Selezionato Condominio Quercie ", this.token);
      // }
      
    /** condominio Quercie ALPINISTI*/
    // if (!environment.production) {
    //   this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiMDAwMDAwMDAwMDAwMDAwMiIsImlhdCI6MTcyNTk1NDY5MCwiZXhwIjoxNzUwNDc1NDY5MH0.kshOVbDx-RbY9_fRkBoLsHT26X0mnRzxGSpNJWO1oVA";
    //   console.log("Selezionato Condominio Quercie ", this.token);
    // }
    
  }

  async verificaAccesso(params: IAuthParams): Promise<boolean> {
    return new Promise((res, rej) => {
      this.http.post(this.api.apiExpress + this.AUTH_CONDOMINI, params).subscribe((a: IAuthResponse) => {
        res(a.Result),
          console.log("Autorizzato ", a.Result["Result"]);

      }, error => {
        rej(error);
        console.error("Errore nell'autenticazione servizio condominio", error);
      })
    })
  }

  async getCondominioToken(codiceFiscale: string): Promise<string> {
    return new Promise((res, rej) => {
      this.http.post(this.api.apiExpress + this.GET_TOKEN, {
        codiceFiscale: codiceFiscale
      }).subscribe((r: any) => {
        this.token = r.Result.token;
        // console.log("token caricato: ", this.token);
        res(this.token)
      }, error => {
        rej(error);
        console.error("Errore nel recupero del token ", error);
      })
    })
  }

}
