import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginCondominiService } from '../Service/login-condomini.service';



@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    urlsToNotUse: Array<string>;

    constructor(
        private loginServ: LoginCondominiService
    ) {
        this.urlsToNotUse= [
            'Tintemix/CheckLogin/.+',
            '/AgileWS/api/.+'
        ];
    }




    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // INtercetta tutte le HttpRequest e inserisce il token
        if (this.isValidRequestForInterceptor(request.url)) {

            request = request.clone({
                //setHeaders: { Authorization: `Basic ${ localStorage.getItem('autho')}` }
                setHeaders: { Authorization: `Bearer ${ this.loginServ.token }` }
                //TODO Rivedere se questa logica è ancora in produzione.
                //TODO Inserire il token per i condomini
                // setHeaders: { Authorization: `Basic bGltTGF2JDM0OmVtMUxAOTg=` }
                // setHeaders: { Authorization: 'Bearer: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RpY2VGaXNjYWxlIjoiMDEyMzQ1Njc4OTEyMzQ1NiIsImlhdCI6MTcxMDgzOTkyMCwiZXhwIjoxNzEzNDMxOTIwfQ.Gez1oRWWJt72LCqXvJRhsyCryFVIaftPBTMX50oBrNo'}
                
            });
            return next.handle(request);
        }
        return next.handle(request);
        }

    // private isValidRequestForInterceptor(requestUrl: string): boolean { 
    //     //TODO Si potrebbe invertire il funzionamento per far mettere per esempio condominiToken
    //     // solamente alle chiamate condomini/.+
    //     let positionIndicator: string = 'api/condomini/';
    //     let position = requestUrl.indexOf(positionIndicator);
    //     console.log("#### token-interceptor ####", "\nrequestUrl:", requestUrl);
    //     if (position > 0) {
    //         let destination: string = requestUrl.substring(position + positionIndicator.length);
    //         for (let address of this.urlsToNotUse) {
    //             console.log(address)
    //             if (new RegExp(address).test(destination)) {
    //                 return false;
    //             }
    //         }
    //     }
    //     return true;
    // }

    private isValidRequestForInterceptor(requestUrl: string): boolean { 
        //Il token mi serve su tutte le chiamate tranne il login
        // perché login punta al database accessi e non al db coi dati condominio

        let positionIndicator: string = 'api/login/';
        // let positionIndicator1: string = 'AgileWS/api/Auth/ValidateToken'
        let position = requestUrl.indexOf(positionIndicator);
        // console.log("#### token-interceptor ####", "\nrequestUrl:", requestUrl);
        if (position === -1) {
            // se non ho trovato 'api/login/' allora è una richiesta valida su cui inserire il token
            let destination: string = requestUrl.substring(position + positionIndicator.length);
            for (let address of this.urlsToNotUse) {
                // console.log(address)
                if (new RegExp(address).test(destination)) {
                    return false;
                }
            }
        }
        return true;
    }
}
