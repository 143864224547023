export const environment = {
  production: true,
  //baseUrl: 'http://93.42.188.163:8083/Ticket/', // test tintoria
  //baseUrl: 'http://192.168.0.100:8080/Ticket/', // San Giorgio
  //baseUrl: 'http://192.168.0.48:8081/Ticket/', // LIM
  
  //baseUrlExpress : 'http://192.168.0.8083/api/', // Live LIM

  baseUrlExpress : '/api/', // virdis
  baseUrl: 'https://web.virdis.si:8082/ticket/', // virdis

  //baseUrlExpress : '/EsgestX/api/', // Artioli
  //baseUrl: 'https://agenti.lavartioli.it:8082/ticket/', // artioli

  defPeriod : 0,
  defPeriodIn : 1,
  artDebug : '',
  vascaDebug : undefined,
  user : 'condomini_test',
  password : 'gestionaleCondomini2024',


  indiriMail: "f.dicasola@emilsoftware.it",
  serverMail: "smtp.qboxmail.com",
  pwdMail: "DcasEs332",
  portMail: "465",
  secureMail: "ssl",
  
  urlCheckToken: "https://cre-ama.ii9.it/AgileWS/api/Auth/ValidateToken",

  codiciFiscaliCondomini: [
    {
      codFis: "COND20240000025",
      nome: "San Rocco 3"
    },
    {
      codFis: "0123456789123456",
      nome: "Condominio Test"
    },
    {
      codFis: "0000000000000001",
      nome: "Condominio Larici"
    },
    {
      codFis: "0000000000000002",
      nome: "Condominio Querce"
    },
    {
      codFis: "0000000000000003",
      nome: "Condominio Salici"
    },
    {
      codFis: "0000000000000004",
      nome: "Condominio Abeti"
    },
    {
      codFis: "0000000000000005",
      nome: "Condominio Pioppi"
    },
    {
      codFis: "0000000000000006",
      nome: "Condominio Frassini"
    },
    {
      codFis: "COND20240000009",
      nome: "COND20240000009"
    },
    {
      codFis: "COND20240000010",
      nome: "COND20240000010"
    },
    {
      codFis: "COND20240000011",
      nome: "COND20240000011"
    },
    {
      codFis: "COND20240000012",
      nome: "COND20240000012"
    },
    {
      codFis: "COND20240000013",
      nome: "COND20240000013"
    },
    {
      codFis: "COND20240000014",
      nome: "COND20240000014"
    },
    {
      codFis: "COND20240000015",
      nome: "COND20240000015"
    },
    {
      codFis: "COND20240000016",
      nome: "COND20240000016"
    },
    {
      codFis: "COND20240000017",
      nome: "COND20240000017"
    },
    {
      codFis: "COND20240000018",
      nome: "COND20240000018"
    },
    {
      codFis: "COND20240000019",
      nome: "COND20240000019"
    },
    {
      codFis: "COND20240000020",
      nome: "COND20240000020"
    },
    {
      codFis: "COND20240000021",
      nome: "COND20240000021"
    },
    {
      codFis: "COND20240000022",
      nome: "COND20240000022"
    },
    {
      codFis: "COND20240000023",
      nome: "COND20240000023"
    }
  ]

};
