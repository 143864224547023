import { Injectable } from '@angular/core';
import {Buffer} from "buffer";
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EncodingService {
  GET_CRYPTAES128_API = 'crypt';
  public jsonCrypt: any;

  constructor(private http:HttpClient,
              private api: ApiService,) { }


  encodeBase64(value: any){
    if (value != undefined) {
      return Buffer.from(value).toString('base64')
    } else return value;
  }

  decodeBase64(value: any){
    const regex = /^\d+(\,\d+)*$/
    if (value != undefined && !value.match(regex)) { // Controlliamo se non è undefined/null e se NON è un numero.
      return Buffer.from(value,'base64').toString()
    } else if (value != undefined && value.match(regex)) { // Controlliamo se non è undefined/null e se è un numero.
      return value;
    } else if (value == undefined) return undefined;

  }

  // async encodeAES128(value:any):Promise<string>{
  //   return  new Promise((resolve, reject) =>  {          
  //     this.http.post(this.api.apiUrl + this.GET_CRYPTAES128_API, 'VALUE='+btoa(value)+"&KEY="+btoa(this.api.AES128key)).subscribe(res => {
  //       var object = JSON.stringify(res);
  //       var json = JSON.parse(object);         
  //       resolve(atob(json.Value)) //viene restituito in base64
  //     },
  //     error => {
  //       console.log(error);
  //       reject("")
  //     });          
  //   });  
  // }

  async encodeAES128(value:any):Promise<string>{
    return  new Promise((resolve, reject) =>  { 

      let params= {
        data : value,
      }

      this.http.post(this.api.apiExpress + this.GET_CRYPTAES128_API, params).subscribe(res => {
        var object = JSON.stringify(res);
        var json = JSON.parse(object);

        resolve(json.Result);
      },
      error => {
        console.log(error);
        reject("")
      });          
    });  
  }
}
