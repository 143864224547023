

<ion-menu side="end" menuId="first" contentId="content" (ionWillOpen)="getAccessi()">
    <ion-header>
      <ion-toolbar color="warning">
        <img alt="logo" src="assets/esgestx_top_logo.png" id="logo">
      </ion-toolbar>
    </ion-header>

    <ion-content color="light" *ngIf="this.areAccessiAvailable">
      <div style="background: white; text-align: center;">
      <ion-menu-toggle auto-hide="true">
        <ion-chip style="background-color: #0199c2; color: whitesmoke; font-weight: bold; margin: 8px;" (click)="doProfilo()" routerDirection="forward">
          <ion-avatar style="margin: 4px;">
            <img  src="./assets/immagini/avatar.svg">
          </ion-avatar>
          <ion-label style="padding: 2px;">{{this.profilo.getNomeCognome()}}</ion-label>
        </ion-chip>
      </ion-menu-toggle>
    </div>
      <ion-list *ngFor="let mnu of this.accesso[0].mnuAbil;">
        <ion-menu-toggle auto-hide="true">
          <ion-title>
            {{mnu.desGrp}}
          </ion-title>
          <ion-item *ngFor="let item of mnu.menu" [routerLink]="item.pagina" routerDirection="forward">
            <ion-icon [name]="item.icon" slot="start"></ion-icon>
              {{item.desMnu}}
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <ion-list *ngIf="this.accesso[0].flgAdmin">
        <ion-menu-toggle auto-hide="true">
          <ion-title>
            ADMIN
          </ion-title>
          <ion-item routerLink="profilo-new" routerDirection="forward">
            <ion-icon name="person-add-sharp" slot="start"></ion-icon>
              Nuovo profilo
          </ion-item>
          <ion-item routerLink="lista-profili" routerDirection="forward">
            <ion-icon name="people-circle-sharp" slot="start"></ion-icon>
              Lista profili
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <ion-list>
        <ion-menu-toggle auto-hide="true">
          <ion-title>
            LOG OUT
          </ion-title>
          <ion-item (click)="doLogOut()">
            <ion-icon name="exit-outline" slot="start"></ion-icon>
              Log out
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
