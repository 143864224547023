import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import {Geolocation} from '@awesome-cordova-plugins/geolocation/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpModule } from './shared/http.module';
import { ApiService } from './Service/api.service';
import localeIt from '@angular/common/locales/it';
import { DatePipe, registerLocaleData } from '@angular/common';
import { Location,HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SwiperModule } from "swiper/angular";
import { LOCALE_ID } from '@angular/core';
import { MenuComponentModule } from './menu/menu.module';
import { IonicSelectableModule } from 'ionic-selectable';
import { ModalConfermaOrdinePage } from './modals/modal-conferma-ordine/modal-conferma-ordine.page';
import { ModalSegnalazionePage } from './modals/modal-segnalazione/modal-segnalazione.page';
import { ModalAumentoDotazPage } from './modals/modal-aumento-dotaz/modal-aumento-dotaz.page';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TokenInterceptor } from './shared/token-interceptor';
//import { ModalDettaglioAnagraficaPage } from './modals/modal-dettaglio-anagrafica/modal-dettaglio-anagrafica.page';

registerLocaleData(localeIt)

@NgModule({
  declarations: [
    AppComponent, 
    ModalConfermaOrdinePage, 
    ModalSegnalazionePage,
    ModalAumentoDotazPage,
    // ModalDettaglioAnagraficaPage,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicSelectableModule,
    NgxDatatableModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule.forRoot({environment}),
    ReactiveFormsModule,
    FormsModule,
    SwiperModule,
    MenuComponentModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: LOCALE_ID, useValue: 'it' },
    ApiService,
    NavParams,
    Geolocation,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


